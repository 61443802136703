@import '../../styles/constants.module';

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  display: flex;
  margin: 16px;
  border-radius: 50%;
  width: 64px;
  svg {
    width: 64px;
    height: 64px;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.circle1 {
  color: white;
}
.circle2 {
  color: white;
  animation-delay: 300ms;
}
.circle3 {
  color: white;
  animation-delay: 600ms;
}

.imagesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  max-width: 500px;
  opacity: 0.7;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($COLOR_YELLOW, 0.5);
  }

  40% {
    box-shadow: 0 0 0 10px rgba($COLOR_YELLOW, 0.5);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($COLOR_YELLOW, 0.5);
  }
}
