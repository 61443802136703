@import '../../../styles/constants.module';

.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $COLOR_LIGHT;
  border-radius: 50%;
}

.svgWrapper {
  position: relative;
  z-index: 1;
  svg {
    // remove whitespace below image
    display: flex;

    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
  }
}

.number {
  color: $COLOR_DARK;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
