@import '../../../styles/constants.module';

.topBar {
  color: $BODY_TEXT !important;
}

.rowsWrapper {
  flex: 1;
}

.topRow {
  flex: 1;
  display: flex;
  background-color: $COLOR_LIGHT;
  padding: (2 * $BASE_UNIT);
  display: flex;
  justify-content: center;
  height: 40px;
  position: relative;
}

.foodcardsIcon {
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: (2 * $BASE_UNIT);
}

.topCenterArea {
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topLeftArea {
  top: (2 * $BASE_UNIT);
  left: (2 * $BASE_UNIT);
  bottom: (2 * $BASE_UNIT);
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
}

.topRightArea {
  top: (2 * $BASE_UNIT);
  right: (2 * $BASE_UNIT);
  bottom: (2 * $BASE_UNIT);
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
}

.bottomRow {
  margin: (1 * $BASE_UNIT) (2 * $BASE_UNIT);
}

.row {
  flex: 1;
  display: flex;
  align-items: center;
}

.bottomLeftArea {
  flex: 1;
}
