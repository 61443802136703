$COLOR_LIGHT: #f1f4fd;
$COLOR_DARK: #484f66;
$BODY_TEXT: $COLOR_DARK;
$COLOR_YELLOW: #ffbe58;
$COLOR_YELLOW_10: rgba(#ffbe58, 0.1);
$COLOR_BLUE: #4161d4;
$COLOR_IMAGE_BACKGROUND: #f8faff;
$COLOR_SUCCESS: #115b07;
$COLOR_WARNING: #ffd800;
$COLOR_ERROR: #7a0c06;

$BASE_UNIT: 4px;

$ANIMATION_DURATION_FAST: 100ms;
$ANIMATION_DURATION_NORMAL: 200ms;
