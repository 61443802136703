@import './constants.module';

@mixin breakpoint-xs {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin breakpoint-s {
  @media (min-width: 600px) and (max-width: 959px) {
    @content;
  }
}
@mixin breakpoint-s-and-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin breakpoint-m {
  @media (min-width: 960px) and (max-width: 1279px) {
    @content;
  }
}

@mixin breakpoint-m-and-up {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin breakpoint-l {
  @media (min-width: 1280px) and (max-width: 1919px) {
    @content;
  }
}
@mixin breakpoint-l-and-up {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin activeOutline {
  position: relative;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    z-index: 0;
    left: -4px;
    right: -4px;
    bottom: -4px;
    top: -4px;
    box-shadow: 0 0 0 2px $COLOR_YELLOW;
    border-radius: 6px;
    transition: all $ANIMATION_DURATION_FAST ease-in-out;
  }
}

@mixin activeOutlineBeforeTransition {
  position: relative;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    z-index: 0;
    left: 4px;
    right: 4px;
    bottom: 4px;
    top: 4px;
    box-shadow: 0 0 0 8px rgba($COLOR_YELLOW, 0);
    border-radius: 4px;
    transition: all $ANIMATION_DURATION_FAST 0.05s;
  }
}
