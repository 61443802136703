@import '@shared/styles.module';

html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
  //   background-color: rgb(238, 238, 238);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $BODY_TEXT;
  //background-color: rgb(238, 238, 238);
}

a,
a:visited,
a:active,
input,
textarea {
  color: $BODY_TEXT;
}
