@import '../../styles/mixins.module';

.cardWrapper {
  width: 100%;

  @include activeOutlineBeforeTransition();

  &.active,
  &.enabled:hover {
    @include activeOutline();
  }
}

.enabled .card {
  cursor: pointer;
}

.card {
  width: 100%;
}
