@import '../../styles/constants.module';

.normal {
  button {
    margin-left: 2 * $BASE_UNIT !important;
    margin-right: 2 * $BASE_UNIT !important;
  }
}

.small {
  button {
    margin-left: 1.5 * $BASE_UNIT !important;
    margin-right: 1.5 * $BASE_UNIT !important;
  }
}

.horizontalButtonGroup {
  display: flex;
  flex: 1;
  align-items: center;

  flex-direction: row;
  button {
    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.horizontalButtonGroup.center {
  justify-content: center;
}

.horizontalButtonGroup.right {
  justify-content: flex-end;
}
